import * as React from "react"
import { SignUpContainer } from "./index.styles"
import { Form, Input, Divider } from "semantic-ui-react"
import { validateForm } from "./Validator"
import { H4 } from "@styles/Global.styles"
import { KpButton } from "@elements/index"
import theme from "@styles/theme"
import { ButtonTypes } from "@utils/Constant"
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types"
import {
  AuthFormLabel,
  AuthSignupLink,
  AuthSignupMessage,
  ErrorMessageContainer,
} from "@styles/Global.forms"
import { FcGoogle } from "react-icons/fc"
import { FaFacebookF } from "react-icons/fa"
import { Link } from "gatsby"
import { Auth } from "aws-amplify"
import { DealerService } from "@services/DealerService"

type SignupSuccessType = {
  success: boolean
  medium: string
  destination: string
}

const initialSignupSuccess: SignupSuccessType = {
  success: false,
  medium: "",
  destination: "",
}

const SignUpForm: React.FC<{}> = (props: any) => {

  let emailP= ""
  let nameP = ""
  if (typeof window !== 'undefined') {
    const queryParameters = new URLSearchParams(window.location.search)
    emailP = queryParameters.get("email")
    nameP = queryParameters.get("name")
  }

  const [name, setName] = React.useState(nameP)
  const {apiUrl } = props
  const [email, setEmail] = React.useState(emailP)
  const [password, setPassword] = React.useState("")
  const [loading, setLoading] = React.useState(false)
  const [signupSuccess, setSignupSuccess] = React.useState<SignupSuccessType>(
    initialSignupSuccess
  )
  const [resent, setResent] = React.useState(false)
  const [error, setError] = React.useState({
    name: "",
    message: "",
  })

  async function signUp(email: string, password: string, name: string) {
    setLoading(true)

    await DealerService.verifySalesPerson(apiUrl, email.trim())
      .then(response => {
        if(response.Status === 200){
          Auth.signUp({
            username: email,
            password,
            attributes: {
              email,
              name,
              "custom:dealer": "allowed"
            }
          })
            .then(res => {
              console.log(res)
              const medium = res.codeDeliveryDetails.AttributeName
              const dest = res.codeDeliveryDetails.Destination
              setSignupSuccess({ success: true, medium: medium, destination: dest })
              setLoading(false)
            })
            .catch(err => {
              setSignupSuccess({ ...initialSignupSuccess, success: false })
              setError(err)
              setLoading(false)
            })
        } else {
          var mes = {
            name: "",
            message: response.Message,
          }

          setError(mes)
          setLoading(false)
        }
      })
      .catch(exception => {
        var mes = {
          name: "",
          message: exception.message,
        }
        setError(mes)
        setLoading(false)
      })
  }

  const resend = () => {
    setResent(false)
    Auth.resendSignUp(email)
      .then(res => {
        console.log(res)
        setResent(true)
      })
      .catch(err => {
        console.log(err)
        setResent(false)
      })
  }

  return (
    <SignUpContainer>
      {signupSuccess.success ? (
        <div>
          <H4 textAlign="center" style={{ paddingBottom: "20px" }}>
            Email Verification
          </H4>
          {resent && <div>Verification Email has been resend!</div>}
          <div>
            A verification link has been sent to your {signupSuccess.medium}{" "}
            {signupSuccess.destination}, please click the link to verify your account and <Link to="/dealer-login/">Login</Link>.
            <br></br> If you haven't received the email,
            {" "}
            <a onClick={resend} href="#">
              click here
            </a>{" "}
            to re-send it.
          </div>
        </div>
      ) : (
        <div>
          <H4 textAlign="center" style={{ paddingBottom: "20px" }}>
            Sign Up
          </H4>
          <Form autoComplete="off"
            onSubmit={e => {
              e.preventDefault()
              if (validateForm(name, email, password, setError)) {
                signUp(email, password, name)
              }
            }}
          >
            <Form.Field>
              <AuthFormLabel>NAME</AuthFormLabel>
              <Input
                type="text"
                name="name"
                value={name}
                id="name"
                placeholder="Full Name"
                onChange={e => setName(e.target.value)}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <AuthFormLabel>EMAIL</AuthFormLabel>
              <Input
                type="email"
                name="email"
                value={email}
                id="email"
                placeholder="Email address"
                onChange={e => setEmail(e.target.value)}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field>
              <AuthFormLabel>PASSWORD</AuthFormLabel>
              <Input
                type="password"
                name="password"
                value={password}
                id="password"
                placeholder="Password"
                onChange={e => setPassword(e.target.value)}
                autoComplete="new-password"
              />
            </Form.Field>
            <Form.Field
              style={{ textAlign: "center", padding: "10px 0 10px 0" }}
            >
              <KpButton
                id="signup-submit"
                color={theme.brand.colors.green}
                buttonType={ButtonTypes.Primary}
                fullWidth="mobile tablet computer"
                type="submit"
              >
                {loading ? "Loading..." : "Create account"}
              </KpButton>
            </Form.Field>
            {error.message &&
              (error.name == "UsernameExistsException" ? (
                <ErrorMessageContainer>
                  An account with this email already exists within this dealership, please contact our Dealer team to provide a unique email address.
                </ErrorMessageContainer>
              ) : (
                <ErrorMessageContainer>{error.message === 'Email not exists.' ? 'Email does not exist' : error.message} {error.message === 'Email not exists.' &&
                (<p> Please call our dealer team on <a href="tel:0800650111">0800 650 111</a> or email us at <a href="mailto:dealers@kiwiplates.nz">dealers@kiwiplates.nz</a>
                </p>)
                }</ErrorMessageContainer>
              ))}
          </Form>
          <div style={{ textAlign: "center", paddingTop: "10px" }}>
            <AuthSignupMessage>Already have an account?</AuthSignupMessage>
            <AuthSignupLink to="/dealer-login">Login now!</AuthSignupLink>
          </div>
        </div>
      )}
    </SignUpContainer>
  )
}
export default SignUpForm
