import React from "react"
import { Layout } from "@components/Layout"

import { SEO } from "@components/SEO"
import Signup from "@dealer-auth/Signup"
import { StaticQuery } from "gatsby"

const DealerSignupPage = () => (
  <Layout version="dealer" authEnabled={true}>
    <SEO title="Sign Up" />
    <StaticQuery query={graphql`
            {
                site {
                    siteMetadata {
                    apiUrl
                    }
                }
            }
            `}
        render={(data) => {
        return (<Signup apiUrl={data.site.siteMetadata.apiUrl}/>)
        }}></StaticQuery>
  </Layout>
)

export default DealerSignupPage
